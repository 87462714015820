.container {
  padding: 1rem 2rem;
}

.container .navContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.container .navContainer .headerSection {
  color: #01486d;
  font-size: 1.2rem;
  margin: 0;
}

.container .tableContainer .headerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.container .tableContainer .headerSection .header {
  font-weight: 500;
  font-size: 1.2rem;
  color: #01486d;
}

.container .tableContainer .headerSection .actionBtn {
  border: none;
  border-radius: 32px;
  background-color: #01486d;
  color: white;
  padding: 0.2rem 1rem;
}
