/* (A) TIMELINE CONTAINER */
.vtl {
  /* (A1) RELATIVE POSITION REQUIRED TO PROPERLY POSITION THE TIMELINE */
  position: relative;

  /* (A2) RESERVE MORE SPACE TO THE LEFT FOR THE TIMELINE */
  padding: 0;

  /* (A3) OPTIONAL WIDTH RESTRICTION */
  max-width: 100%;
}
.vtl,
.vtl * {
  box-sizing: border-box;
}

.vtl::before {
  /* (B1) VERTICAL LINE */
  content: "";
  width: 2px;
  background-color: #c4c4c4;

  /* (B2) POSITION TO THE LEFT */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
}

/* (B) DRAW VERTICAL LINE USING ::BEFORE */
div.event::before {
  /* (B1) VERTICAL LINE */
  content: "";
  width: 2px;
  background-color: #c4c4c4;

  /* (B2) POSITION TO THE LEFT */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
}

div.event.last::before {
  width: 2px;
  background-color: white;
  top: 45px;
}

/* (C) COSMETICS FOR EVENTS */
div.event {
  padding: 20px 30px;
  font-family: "Lato", sans-serif;
  position: relative;
  border-radius: 6px;
}

div.event.first {
  padding-top: 0;
}

/* (D) COSMETICS FOR EVENT DATE & TEXT */
p.date {
  font-size: 1.1em;
  font-weight: 700;
  color: #0077b5;
}
p span {
  margin: 10px 0 0 0;
  color: #222;
  font-weight: 400;
  font-size: 1rem;
}

/* (E) EVENT "SPEECH BUBBLE CALLOUT" */

/* (F) CIRCLE ON TIMELINE */
div.event::after {
  /* (F1) "MAGIC CIRCLE" */
  content: "";
  background: #0077b5;
  border: 4px solid #0077b5;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  /* (F2) POSITION TO THE LEFT */
  position: absolute;
  top: 20%;
  left: -7px;
}

div.event.first::after {
  top: 0;
}
