.textWrapper{
 width: fit-content;
 margin-bottom: 30px;
}

.summaryText{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.3px;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}

.underlineText{
  height: 6px;
  background: #5DBE94;
  border-radius: 20px;
}

