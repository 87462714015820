.header {
  text-align: center;
  margin-top: 1.2rem;
  width: 80%;
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.info {
  color: #c4c4c4;
  margin-top: 0.8rem;
  text-align: center;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.2rem;
  margin-top: 1.3rem;
}

.btnContainer .cancel {
  border: none;
  background-color: transparent;
}

.btnContainer .confirm {
  background-color: #b94e54;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 32px;
}
