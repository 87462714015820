.wrapper{
    gap: 20px;
}

.wrapper h4{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
}