.container {
  padding: 1rem 2rem;
}

.container .navContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}

.container .navContainer .headerSection {
  color: #01486d;
  font-size: 1.5rem;
  margin: 0;
}

.container .navContainer .actionsContainer {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container .navContainer .actionsContainer .searchContainer {
  width: 70%;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 0.5rem 0.5rem;
  border-radius: 32px;
}

.container .navContainer .actionsContainer .searchContainer .svg {
  width: 10%;
}

.container .navContainer .actionsContainer .searchContainer .searchInput {
  background-color: transparent;
  width: 90%;
  border: none;
  outline: none;
  padding-left: 0.8rem;
}

.container .tableTitle {
  font-size: 1.3rem;
}

.container .solvedTable {
  margin-top: 6rem;
}
