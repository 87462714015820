.wrapper{
    width: 550px;
    height: 450px;
    background: #FFFFFF;
    border-radius: 31px;
    padding: 40px;
    gap:20px
}

.wrapper h3{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    color: #01486D;
}

.wrapper input{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;   
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    width: 100%;
    padding: 10px 20px;
}

.wrapper input::placeholder{
    color: rgba(208,208,208, 0.5);
}

.wrapper h6{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.wrapper h6 a{
    color: #000000;
}

.wrapper button{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #FFFFFF;
    width: 100%;
    padding: 10px 0;
    background: #0077B5;
    border-radius: 31px;
    border: none;
}

.forgotPasswordText{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}