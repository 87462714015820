.container {
  padding: 1rem 2rem;
}

.container .navContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.container .navContainer .headerSection {
  color: #01486d;
  font-size: 1.2rem;
  margin: 0;
}

.container .tableContainer .headerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.container .tableContainer .headerSection .header {
  font-weight: 500;
  font-size: 1.2rem;
  color: #01486d;
}

.container .tableContainer .headerSection .selectContainer {
  border: none;
  border-radius: 32px;
  background-color: #01486d;
  color: white;
  padding: 0.4rem 1rem;
  display: flex;
  position: relative;
}

.container .tableContainer .headerSection .selectContainer svg {
  position: absolute;
  top: 0.9rem;
  right: 1rem;
}
.container .tableContainer .headerSection .selectContainer select {
  appearance: none;
  background-color: transparent;
  color: white;
  border: none;
  width: 100%;
  outline: none;
  padding-right: 2rem;
}

.container .tableContainer .tableButton {
  border: none;
  background-color: #0077b5;
  color: white;
  border-radius: 32px;
  padding: 0.3rem 1rem;
}
