.wrapper{
    min-height: 100vh;
    background: url("https://s3.amazonaws.com/cdn.traders-central/prime-bridge-admin/bridge-admin-bg.jpg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
}

.wrapper h2{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    color: #000000;
}

.employeeLoginButton{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    background: #01486D;
    border-radius: 31px;
    width: 550px;
    margin-top: 20px;
    border: none;
    padding: 10px 0;
}