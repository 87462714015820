@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Lato", sans-serif;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  list-style: none;
  outline: none;
  color: #0077B5 ;
}
.pagination > .active > a{
  background: #0077B5;
  border-color: #0077B5 ;
  color: #fff !important;
}
.pagination > li > a{
  border: 0.840647px solid #0077B5;
  border-radius: 3.36259px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 5px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background: #0077B5;
  border: 0.840647px solid #0077B5;
  border-radius: 3.36259px;
  outline: none ;
}
/* .pagination > li > a, .pagination > li > span{
  color: #0077B5 ;
} */
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
  display: none;
}

.ReactFlagsSelect-module_selectBtn__19wW7{
  border: none !important;
}
