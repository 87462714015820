
.cardContainer {
  border-radius: 30px;
  background-color: #ffffff;
  padding: 3rem 3.5rem;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0px 0px 10px 4px rgba(45, 136, 136, 0.05);
}

.cardInner {
  line-height: 2.5rem;
  width: 100%;
}

.cardInner h2 {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-start;
}

.cardInner div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1px;
}

.cardInner p {
  margin-bottom: 0;
}

.cardInner div div {
  color: #41b5bd;
}

.card1Top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardBottom {
  height: 14px;
  width: 100%;
}

.bagley {
  background-color: #0077B5;
  border-radius: 10px;
  padding: 0.7rem;
  width: 60px !important;
  height: 60px !important;
  margin-bottom: 1rem;
}

.card1Bottom {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.rows {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rows2 {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* STATISTICS SECTION STYLES */
.statSection {
  width: 95vw;
  margin-top: 40px;
  margin-bottom: 60px;
}

.vertical {
  border-left: 1px solid #D9D9D9;
  height: 200px;
  /* position:absolute;
  left: 0%; */
}

.summaryText{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: #000000;
  text-align: left;
}

.numText{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.3px;
  color: #41B5BD;
}

.statsText{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: #000000;
  text-align: left;
}

.statTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0077B5;
  margin-bottom: 30px;
}

.innerStats{
  width: 30vw;
  margin: 20px 0;
}

/* MONTHLY GAIN STYLES */
.gainSection {
  height: 500px;
  background-color: #ffffff;
  width: 90%;
  margin: auto;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.gainCard {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 4px rgba(45, 136, 136, 0.05);
}

.gainCardBody {
  height: 95%;
  width: 100%;
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
}

.cardBodyInner {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 2rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.cardColumn {
  display: flex;
  flex-direction: column;
}

.cardColumn div {
  text-transform: capitalize;
  font-weight: 800;
  margin-bottom: 1.5rem;
}

.gainCardBottom {
  height: 5%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* TRADES SECTION STYLES */
.tradeSection {
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  padding-bottom: 2rem;
}

/* TOP SECTION STYLES */
.btnDiv {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  background-color: transparent !important;
}

.topSectionContainer {
  padding: 0 3.5rem 0 3rem;
  display: flex;
}

.left {
  display: flex;
  gap: 1.5rem;
}

.left div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left div p {
  cursor: pointer;
  color: #666666;
  font-weight: 600;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}

.left div span {
  height: 4px;
  width: 100%;
  transition: background-color 1s !important;
}

.left div div:before {
  transition: width 0.2s ease, background-color 0.2s ease;
}
.left div div:after {
  transition: width 0.2s ease, background-color 0.2s ease;
}

/* .right {
  display: flex;
  gap: 0.8rem;
  padding-bottom: 1rem;
} */

.right button {
  height: 40px !important;
}

.monthsHeader{
 border-bottom: 1px solid #D9D9D9;
}

.yearText{
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #000000;
}

.yearNum{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: #0077B5;
  margin-top: 20px;
}

.tabText{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.3px;
  color: #000000;
  padding: 15px 30px;
}

.activeTab{
  background: #000000;
  border-radius: 32px;
  color: #ffffff;
  padding: 15px 30px;
}

.tabsWrapper{
 width: 300px;
}

.statsSlider{
  width: 100%;
  height: 350px;
  padding: 40px;
}

.sliderCard{
 padding: 0 10px;
}

.statsWrapper{
  padding: 0 10px;
}

/* MEDIA QUERIES */

@media (max-width: 767px) {
  .topSectionContainer {
    padding: 0 1.5rem 0 1rem;
  }

  .left div p {
    margin-bottom: 0.5rem;
  }

  .left {
    display: flex;
    gap: 1.2rem;
  }

  .right svg{
    height: 15px !important;
  }

  .statTitle {
    font-size: 24px;
    text-align: center;
  }
}

@media (max-width: 480px){
  .statTitle {
    font-size: 20px;
  }
}
