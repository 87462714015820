.header {
  text-align: center;
  margin-top: 1.2rem;
  width: 80%;
  font-size: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  margin-bottom: 2rem;
}

.confirm {
  background-color: #0077b5;
  color: white;
  padding: 0.5rem 3rem;
  border: none;
  border-radius: 32px;
}

.container {
  padding: 0rem 1.5rem;
}

.group {
  display: flex;
  gap: 2rem;
}

.inputContainer {
  margin-bottom: 1.2rem;
}

.group .inputContainer {
  width: 50%;
}

.inputContainer .label {
  text-align: center;
  margin-bottom: 0.3rem;
  color: #c4c4c4;
}
.inputContainer .input{
  background-color: #f8f8f8;
  color: #141414;
  padding: 0.8rem 1rem;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 32px;
  outline: none;
}

/* .dropDownWrapper{
  position: relative;
  margin-bottom: 80px;
} */

/* .inputContainer .dropDown{
  position: absolute;
  left: 0;
  top: 0;
} */

.inputContainer .selectContainer {
  background-color: #f8f8f8;
  position: relative;
  padding: 0.8rem 1rem;
  width: 100%;
  border-radius: 32px;
}

.inputContainer .selectContainer svg {
  position: absolute;
  top: 1.2rem;
  right: 1rem;
}

.inputContainer .selectContainer select {
  border: none;
  outline: none;
  width: 100%;
  appearance: none;
  background-color: transparent;
  text-align: center;
}

.inputContainer .selectContainer select:invalid {
  color: #c4c4c4 !important;
}

.inputContainer .passwordContainer {
  display: flex;
  background-color: #f8f8f8;
  padding: 0.4rem 1rem;
  border-radius: 32px;
}

.inputContainer .passwordContainer button {
  background-color: #0077b5;
  color: white;
  border: none;
  border-radius: 32px;
  padding: 0.1rem 1rem;
}

.modal {
  max-width: 600px;
}

.inputContainer .input.server_input {
  color: #0077b5;
}

.passwordContent {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.passwordContent .loginContainer {
  text-align: center;
  margin-bottom: 2rem;
}

.passwordContent .loginContainer .label {
  color: #c4c4c4;
  font-weight: 500;
}

.passwordContent .loginContainer .value {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.passwordContent .loginContainer .value svg {
  cursor: pointer;
}
