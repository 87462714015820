.radioLabel{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.checkboxContainer input[type=checkbox]{
    visibility:hidden;
    -webkit-appearance: none;
}

.checkboxContainer input[type='checkbox']:after {
    width: 18px;
    height: 18px;
    /* border-radius: 15px; */
    position: relative;
    background-color: transparent;
    border: 1px solid #D0D0D0;
    border-radius: 50%;
    content: '';
    margin-top: 10px;
    display: inline-block;
    visibility: visible;
}

.checkboxContainer input[type='checkbox']:checked:after {
    width: 18px;
    height: 18px;
    /* border-radius: 15px; */
    position: relative;
    background: #0077B5;
    border: 1px solid #0077B5;
    border-radius: 50%;
    content: '';
    display: inline-block;
    visibility: visible;
}
  
  .btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.2rem;
  margin-top: 1.3rem;
}