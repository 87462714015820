.header {
  text-align: center;
  margin-top: 1.2rem;
  width: 80%;
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.subHeader{
  text-align: center;
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.info {
  color: #c4c4c4;
  margin-top: 0.8rem;
  text-align: center;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.2rem;
  margin-top: 1.3rem;
}

.btnContainer .cancel {
  border: none;
  background-color: transparent;
}

.btnContainer .confirm {
  background-color: #b94e54;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 32px;
}

.radioLabel{
  color:  #141414;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.checkboxContainerArounded input[type=checkbox], .checkboxContainer input[type=checkbox]{
  visibility:hidden;
  -webkit-appearance: none;
}

.checkboxContainerArounded input[type='checkbox']:after, .checkboxContainer input[type='checkbox']:after {
  width: 20px;
  height: 20px;
  /* border-radius: 15px; */
  position: relative;
  background-color: transparent;
  border: 1.5px solid rgba(208,208,208, 0.5);
  border-radius: 5px;
  content: '';
  margin-top: 5px;
  display: inline-block;
  visibility: visible;
}

.checkboxContainerArounded input[type='checkbox']:checked:after, .checkboxContainer input[type='checkbox']:checked:after {
  width: 20px;
  height: 20px;
  /* border-radius: 15px; */
  position: relative;
  background: #0077B5;
  border: 1.5px solid #0077B5;
  border-radius: 5px;
  content: '';
  display: inline-block;
  visibility: visible;
}

.checkboxContainerArounded input[type='checkbox']:after, .checkboxContainerArounded input[type='checkbox']:checked:after{
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.placeholder{
  color: #C4C4C4;
  font-size: 16px;
  font-style: normal;; 
}



/* Customize the label (the container) */
.check_cont {
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check_cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_cont p {
  font-size: 1rem;
}

/* Create a custom checkbox */
.checkmark {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 20px;
  width: 20px;
  border: solid 1px #d0d0d0;
  border-radius: 5px;

  /* background-color: #eee; */
}

/* On mouse-over, add a grey background color */
.check_cont:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check_cont input:checked ~ .checkmark {
  background-color: #0077b5;
  border: solid 1px #0077b5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_cont .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  /* border: solid white; */
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.actionContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.actionContainer .actionText {
  color: #0077b5;
}

.disContainer h5{
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}


.container {
  min-width: 100%;
  border-top-width: calc(1px * calc(1 - 0));
  border-bottom-width: calc(1px * var(0));
}

.container .head {
  padding-top: 0.875rem /* 14px */;
  padding-bottom: 0.875rem /* 14px */;
  padding-left: 1rem /* 16px */;
  /* padding-right: 0.75rem 12px; */
  /* text-align: left; */
  font-weight: 600;
  /* color: #0077b5; */
  font-size: 1.2rem;
  /* border-bottom: solid 1px #c4c4c4; */
}

.container .head.first {
  padding-left: 0rem /* 16px */;
}

/* .container .body {
  --tw-divide-opacity: 1;
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
} */

/* .container .body tr {
  border-bottom: solid 0.5px #c4c4c4;
} */

.container .body .row {
  white-space: nowrap;
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
  padding-left: 1rem /* 16px */;
  padding-right: 0.75rem /* 12px */;

  /* font-weight: 500; */
}

.container .body .row.first {
  padding-left: 0rem /* 16px */;
}

.container .body .row .svg {
  margin-right: 0.2rem;
}

.container .body .row a {
  color: #0077b5;
  text-decoration: none;
}

.container .body .row .flexItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .body .row .flexItem p {
  margin: 0;
}

.container .body .row .flexItem svg {
  cursor: pointer;
}
