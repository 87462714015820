.modal {
  max-width: 600px;
}

.modal.custom {
  max-width: 680px;
}

.documentModal{
  max-width: 720px;
}

.modal .body {
  padding: 2rem 3rem;
}

.modal .body .headerSection {
  margin-bottom: 1.5rem;
}

.modal .body .headerSection .header {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}

.modal .body .group {
  display: flex;
  justify-content: space-between;
}

.modal .body .group .groupItem {
  width: 45%;
  margin-bottom: 1rem;
}

.modal .body .label {
  color: #c4c4c4;
  font-size: 1rem;
}

.modal .body .value {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  gap: 0.8rem;
}

.modal .body .value.blue {
  color: #0077b5;
}

.modal .body .value svg {
  cursor: pointer;
}

.modal .form_group {
  display: flex;
  padding: 0.5rem 1rem;
  background-color: #f8f8f8;
  border-radius: 32px;
}

.modal .form_group input {
  width: 60%;
  background-color: transparent;
  border: none;
  outline: none;
}

.modal .form_group button {
  color: white;
  background-color: #01486d;
  border: none;
  border-radius: 32px;
  padding: 0.3rem 1.2rem;
}

.modal .body .actionBox {
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: solid 1px #c4c4c4;
  display: flex;
}

.selectContainer {
  margin-bottom: 2rem;

  display: flex;
}

.selectContainer .content {
  width: 100%;
}

.selectContainer .reasonSelect {
  position: relative;
  padding: 0.5rem 1.5rem;
  border-radius: 32px;
  background-color: #f9f9f9;
  width: 90%;
}

.selectContainer .reasonSelect select {
  appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
}

.selectContainer .reasonSelect svg {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal .body .decision {
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: solid 1px #c4c4c4;
  text-align: center;
}

.modal.custom .body .decision {
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: none;
  text-align: left;
  color: #0077b5;
}

.modal .body .decision .status {
  margin-bottom: 0.4rem;
  font-weight: 500;
}

.modal .body .decision .status.success {
  color: #5dbe94;
}

.modal .body .decision .status.danger {
  color: #bd3437;
}

.modal .body .actionBox.second {
  border: none;
  margin-top: 1rem;
  padding-top: 0rem;
  align-self: center;
}

.modal .body .actionBox.second .heading {
  display: flex;
  align-items: center;
}

.modal .body .actionBox .heading {
  width: 30%;
}

.modal .body .actionBox .content {
  width: 80%;
}

.modal .body .actionBox .statuses {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  justify-content: flex-end;
  width: 90%;
}

.modal .body .actionBox .statuses label {
  font-size: 1rem;
}

.modal .body .actionBox .reasonSelect {
  position: relative;
  padding: 0.5rem 1.5rem;
  border-radius: 32px;
  background-color: #f9f9f9;
  width: 90%;
}

.modal .body .actionBox .reasonSelect select {
  appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
}

.modal .body .actionBox .reasonSelect svg {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal .body .actionBtn {
  background-color: #0077b5;
  color: white;
  border: none;
  border-radius: 32px;
  padding: 0.5rem 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  font-size: 1.1rem;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0077b5;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: transparent;
}

.documentBox {
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: solid 1px #c4c4c4;
}

.documentBox .content {
  margin-top: 1rem;
}

.documentBox .content .iconContainer {
  margin-left: 0rem;
}

.documentBox .content .iconContainer svg {
  margin: 0 1rem;
  cursor: pointer;
}

.documentBox .content .compliantItem {
  display: flex;
  margin-bottom: 1rem;
}

.compliantItem p{
  word-wrap: break-word;
  width: 150px;
}

.inputContainer {
  margin-bottom: 1.2rem;
}

.group .inputContainer {
  width: 50%;
}

.inputContainer .label {
  text-align: center;
  margin-bottom: 0.3rem;
  color: #c4c4c4;
}
.inputContainer .input {
  background-color: #f8f8f8;
  color: #141414;
  padding: 0.8rem 1rem;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 32px;
  outline: none;
}

.inputContainer .selectContainer {
  background-color: #f8f8f8;
  position: relative;
  padding: 0.8rem 1rem;
  width: 100%;
  border-radius: 32px;
}

.inputContainer .selectContainer svg {
  position: absolute;
  top: 1.2rem;
  right: 1rem;
}

.inputContainer .selectContainer select {
  border: none;
  outline: none;
  width: 100%;
  appearance: none;
  background-color: transparent;
  text-align: center;
}

.inputContainer .selectContainer select:invalid {
  color: #c4c4c4 !important;
}






.selectWrapper {
  background-color: #f8f8f8;
  position: relative;
  padding: 0.5rem 1rem;
  width: 320px;
  border-radius: 32px;
}

.selectWrapper svg {
  position: absolute;
  top: 1.2rem;
  right: 1rem;
}

.selectWrapper select {
  border: none;
  outline: none;
  width: 100%;
  appearance: none;
  background-color: transparent;
  text-align: center;
 
}

.selectWrapper select:invalid {
  color: #c4c4c4 !important;
}





.group {
  display: flex;
  gap: 2rem;
}

.message_area {
  margin-top: 0.5rem;
}

.message_area .textbox {
  width: 100%;
  background-color: #e5f1f7;
  border: none;
  resize: none;
  border-radius: 20px;
  margin-top: 1rem;
  outline: none;
  padding: 1rem;
}

.message_area .textbox::placeholder {
  color: #c4c4c4;
}

.checkCircle{
  border-radius: 100%;
  border: 2px solid #01486D;
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.horizontalDivider{
  height: 1px;
  width: 100%;
  background: #D0D0D0;
}

.stepWrapper{
  height: fit-content;
}

.stepWrapper h6{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.stepWrapper h6 span{
  color: #01486D;
}

.stepWrapper p{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #141414;
}

.stepNav{
  width: fit-content;
  /* margin-top: 3px; */
  margin-right: 10px;
}

.stepDot{
  width: 14px;
  height: 14px;
  background: linear-gradient(0deg, #01486D, #01486D), linear-gradient(0deg, #0077B5, #0077B5), #0077B5;
  border-radius: 100%;
}

.verticleDivider{
  width: 2px;
  min-height: 105px;
  background: #C4C4C4;
}

.reasonTextInput input{
  background: #F9F9F9;
  border-radius: 26.5px;
  border: none;
  padding: 8px 10px;
  width: 350px;
  margin-right: 35px;
}

