.container {
  padding: 1rem 2rem;
}

.container .navContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.searchContainer {
  width: 300px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 0.5rem 0.5rem;
  border-radius: 32px;
}

.searchContainer input{
  background-color: transparent;
    width: 90%;
  border: none;
  outline: none;
}

.container .navContainer .headerSection {
  color: #01486d;
  font-size: 1.2rem;
  margin: 0;
}

.container .tableContainer .headerSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.container .tableContainer .headerSection .header {
  font-weight: 500;
  font-size: 1.5rem;
}

.container .tableContainer .headerSection .actionBtn {
  border: none;
  border-radius: 32px;
  background-color: #01486d;
  color: white;
  padding: 0.2rem 1rem;
}

.container .spacer {
  margin-top: 5rem;
}
