p {
  margin: 0px;
}

.container {
  padding: 1rem 2rem;
}

.container .navContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}

.container .navContainer .headerSection {
  color: #01486d;
  font-size: 1.5rem;
  margin: 0;
}

.container .headerContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #c4c4c4;
  padding-bottom: 0.4rem;
}

.container .headerContainer .headerText {
  font-size: 1.4rem;
}

.container .headerContainer .actionContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.container .headerContainer .actionContainer .actionText {
  color: #0077b5;
}

.container .compInfo {
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.container .compInfo .compInfoItem p {
  font-weight: 600;
  margin: 1rem;
}

.container .compInfo .compInfoItem.first p {
  margin-left: 0;
}

.container .compInfo .compInfoItem p span {
  font-weight: 400;
}

.accountSection {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.accountSection .accountItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.accountSection .accountItem svg {
  cursor: pointer;
}

.accountSection .accountItem .accountItemText {
  font-weight: 500;
}

.transaction {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.transaction .transactionItem {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.transaction .transactionItem .transactionText {
  display: flex;
  gap: 1rem;
  font-weight: 500;
  align-items: center;
}

.transaction .transactionItem .transactionText span {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-weight: 400;
}

.transaction .transactionItem .transactionText span svg {
  cursor: pointer;
}

.transaction .transactionItem .transactionEdit {
  gap: 1rem;
  display: flex;
  align-items: center;
}

.transaction .transactionItem .transactionEdit select {
  border: none;
  outline: none;
}

.transaction .transactionItem .transactionEdit button {
  border: none;
  background: #01486d;
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 32px;
}

.compliantContainer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 0px;
}

.compliantContainer .compliantItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 50%;
}

.teamsTable {
  min-width: 100%;
  border-top-width: calc(1px * calc(1 - 0));
  border-bottom-width: calc(1px * var(0));
  margin-bottom: 2rem;
}

.teamsTable .teamsHeader {
  padding-top: 0.875rem /* 14px */;
  padding-bottom: 0.875rem /* 14px */;
  padding-right: 0.75rem /* 12px */;
  text-align: left;
  font-weight: 400;
  border-bottom: solid 1px #c4c4c4;
  font-size: 1.5rem;
}

.teamsTable .body tr {
  /* border-bottom: solid 0.5px #c4c4c4; */
}

.teamsTable .body .row {
  white-space: nowrap;
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
  padding-right: 0.75rem /* 12px */;

  /* font-weight: 500; */
}

.teamsTable .body .row.first {
  padding-left: 0rem;
}

.popover_item {
  display: flex !important;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.popover_item.last {
  margin-bottom: 0.2rem;
}

.popover_item p {
  margin: 0;
}

.popover_item.isLast {
  margin-bottom: 0;
}
