/* Customize the label (the container) */
.check_cont {
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check_cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_cont p {
  font-size: 1rem;
}

/* Create a custom checkbox */
.checkmark {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 25px;
  width: 25px;
  border: solid 1px #d0d0d0;
  border-radius: 5px;

  /* background-color: #eee; */
}

/* On mouse-over, add a grey background color */
.check_cont:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check_cont input:checked ~ .checkmark {
  background-color: #0077b5;
  border: solid 1px #0077b5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_cont .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  /* border: solid white; */
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container {
  padding: 2rem 7rem;
}
.container .header {
  margin-bottom: 4rem;
}

.container .header .name {
  color: #01486d;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.container .header .title {
  font-size: 1.5rem;
}

.container .form .title {
  font-size: 1.2rem;
  margin-bottom: 1.4rem;
  font-weight: 500;
}

.container .form .access .title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-top: 2.6rem;
  font-weight: 500;
}

.group {
  display: flex;
  gap: 2rem;
}

.inputContainer {
  margin-bottom: 1.2rem;
}

.inputPassword {
  display: flex;
  gap: 1rem;
  width: 50%;
  align-items: center;
  border: solid 1px #d0d0d0;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 32px;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}

.inputPassword input {
  width: 100%;
  display: block;
  border: none;

  outline: none;
}

.inputPassword input ::placeholder {
  color: #c4c4c4;
}

.group .inputContainer {
  width: 50%;
}

.inputContainer .label {
  text-align: center;
  margin-bottom: 0.3rem;
  color: #c4c4c4;
}
.inputContainer .input {
  /* background-color: #f8f8f8; */
  color: #141414;
  padding: 0.6rem 1.3rem;
  text-align: left;
  width: 100%;
  border: solid 1px #d0d0d0;
  border-radius: 32px;
  outline: none;
}

.inputContainer .input::placeholder {
  color: #c4c4c4;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2.5rem;
  align-items: center;
}

.btnContainer .cancel {
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
}

.btnContainer .confirm {
  background-color: #0077b5;
  color: white;
  border: none;
  padding: 0.5rem 3.5rem;
  border-radius: 32px;
}

.groupItem {
  width: 50%;
}
