.sidebar_container {
  display: none;
}

.sidebar_name {
  color: white;
  margin-bottom: 2rem;
  margin-left: 3rem;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
}

.content_container main .mainDiv .psedoContainer {
  padding-left: 1.5rem /* 24px */;
  padding-right: 1.5rem /* 24px */;
}
@media screen and (min-width: 960px) {
  .sidebar_container {
    display: flex;

    width: 18rem;
    flex-direction: column;
    position: fixed;
    top: 0px;
    bottom: 0px;
  }

  .sidebar_content {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    background-color: #01486d;
  }

  .sidebar_linksContainer {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 16px;
    overflow-y: auto;
  }

  .sidebar_logoContainer {
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
    flex-shrink: 0;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
  }

  .sidebar_logoContainer svg {
    width: 70%;
    /* margin-left: auto;
    margin-right: auto; */
    margin-left: 1rem;
  }

  .content_container {
    padding-left: 18rem /* 256px */;
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
  }

  .content_container main {
    flex: 1 1 0%;
  }

  .content_container main .mainDiv {
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
  }

  .content_container main .mainDiv .psedoContainer {
    max-width: 80rem /* 1280px */;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
  }

  .content_container main .mainDiv .psedoContainer .innerContainer {
    padding-top: 1rem /* 16px */;
    padding-bottom: 1rem /* 16px */;
  }

  .nav_items {
    margin-top: 2rem /* 20px */;
    flex: 1 1 0%;
    padding-left: 0.5rem /* 8px */;
    padding-right: 0.5rem /* 8px */;
  }

  .nav_items .nav_item {
    display: flex;
    align-items: center;
    padding-left: 0.5rem /* 8px */;
    padding-right: 0.5rem /* 8px */;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    border-radius: 32px;
    width: 80%;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    margin-top: 1.2rem;
    color: #ffffff;
  }

  .nav_items .nav_item.selected {
    background-color: #e5f1f7;
    color: #0077b5;
  }

  .nav_items .subRoute {
    display: none;
  }

  .nav_items .subRoute.selected {
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
  }

  .nav_items .subRoute .nav_item {
    margin-left: 0;
    padding-left: 0;
  }

  .nav_items .subRoute .nav_item.selected {
    background-color: transparent;
    color: #ffffff;
    margin-top: 0.5rem;
    margin-left: 0rem;
    position: relative;
    display: inline-block;
    width: auto;
    padding-left: 0rem;
    padding-right: 0;
  }

  .nav_items .subRoute .nav_item.selected::before {
    content: "";
    width: 100%;
    display: block;
    bottom: 0px;
    left: 0;
    position: absolute;
    margin: 0 0%;
    height: 2px;
    background-color: #ffffff;
    transition: width 5s ease-in-out;
    border-radius: 10px;
  }
}
