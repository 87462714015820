.select {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  border: solid 1px #d0d0d0;
  padding: 0.5rem 1rem;
  border-radius: 32px;
}

.select select {
  appearance: none;
  border: none;
  width: 100%;
  padding-left: 8%;
  outline: none;
  font-size: 1.2rem;
  color: #0077b5;
}

.select svg {
  position: absolute;
}

.accDetail {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.accDetail p {
  font-size: 1.1rem;
}

.accDetail .detailItem {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.2rem;
  align-items: center;
}

.accDetail .detailItem svg {
  cursor: pointer;
}

.accDetail .detailItem .value {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.container {
  min-width: 100%;
  border-top-width: calc(1px * calc(1 - 0));
  border-bottom-width: calc(1px * var(0));
}

.container .header {
  padding-top: 0.875rem /* 14px */;
  padding-bottom: 0.875rem /* 14px */;
  padding-left: 1rem /* 16px */;
  padding-right: 0.75rem /* 12px */;
  text-align: left;
  font-weight: 600;
  color: #0077b5;
  font-size: 18px;
  /* border-bottom: solid 1px #c4c4c4; */
}

.container .header.first {
  padding-left: 0rem /* 16px */;
}

.container .body {
  --tw-divide-opacity: 1;
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.container .body tr {
  border-bottom: solid 0.5px #c4c4c4;
}

.container .body .row {
  white-space: nowrap;
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
  padding-left: 1rem /* 16px */;
  padding-right: 0.75rem /* 12px */;

  /* font-weight: 500; */
}

.container .body .row.first {
  padding-left: 0rem /* 16px */;
}

.container .body .row .svg {
  margin-right: 0.2rem;
}

.container .body .row a {
  color: #0077b5;
  text-decoration: none;
}

.container .body .row .flexItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .body .row .flexItem p {
  margin: 0;
}

.container .body .row .flexItem svg {
  cursor: pointer;
}

.progressBar{
  background: #F9F9F9;
  border-radius: 26.5px;
  width: 162px;
  height: 15px;
}

.innerBar{
  background: #01486D;
  border-radius: 26.5px;
  height: 15px;
}

.documentsCount{
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.docIconWrapper{
  width: 25px;
}

