.statsCard{
    /* height: 321px; */
    /* border: 2px solid #59BAC1; */
    border-radius: 30px;
    /* padding: 30px 50px; */
  }

@media (max-width: 768px) {
  .statsCard{
    padding: 30px 20px;
  }
}  